import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const PageUnderconstruction = () => (
  <Layout>
    <Seo title="About Us" />
    <main>
      <div className="container px-5">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-xxl-6">
            <div className="text-center my-5">
              <h1 className="fw-bolder mb-3">ページ作成中です。</h1>
              <p>現在、ページ作成中です。しばらくお待ちください。</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  </Layout>
)

export default PageUnderconstruction
